import styled from "styled-components";

export const AboutLogoContainer = styled.div`
  max-width: 25em;
`;

export const SubscribeInputContainer = styled.div`
  display: flex;
`;

export const SubscribeInput = styled.input``;
